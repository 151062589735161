<template>
  <v-container>
    <v-row style="margin: 30px 0 0 -90px;">
      <v-col :cols="2" style="display:flex;justify-content:end"><p>体能</p></v-col>
      <v-col :cols="2"  class="dl-flex align-center">
        <v-select
            style="display:inline-block"
            clearable
            multiple
            :menu-props="{ offsetY: true }"
            :items="physicalFitnessOption"
            v-model="physicalFitnessSelection"
            dense
            outlined
        ></v-select>
      </v-col>
      <v-col :cols="1"></v-col>
      <v-col :cols="1" style="display:flex;justify-content:end"><p>基因名称</p></v-col>
      <v-col :cols="2" class="dl-flex align-center">
        <v-text-field
          outlined
          dense
          style="display:inline-block"
          v-model="geneName"
        ></v-text-field>
      </v-col>
      <v-col :cols="1"></v-col>
      <v-col :cols="1" style="display:flex;justify-content:end"><p>基因类型</p></v-col>
      <v-col :cols="2"  class="dl-flex align-center">
        <v-select
            style="display:inline-block"
            clearable
            :menu-props="{ offsetY: true }"
            :items="geneTypeOption"
            v-model="geneTypeSelection"
            dense
            outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row style="margin-left: -90px;">
      <v-col :cols="2" style="display:flex;justify-content:end"><p>染色体名称</p></v-col>
      <v-col :cols="2" class="dl-flex align-center">
        <v-text-field
            outlined
            dense
            style="display:inline-block"
            v-model="RNAName"
        ></v-text-field>
      </v-col>
      <v-col :cols="2" style="display:flex;justify-content:end"><p>染色体区间</p></v-col>
      <v-col :cols="2" class="dl-flex align-center">
        <v-text-field
            outlined
            dense
            style="display:inline-block"
            v-model.number="chromosomalIntervalStart"
        ></v-text-field>
      </v-col>
      <v-col :cols="2" class="dl-flex align-center">
        <v-text-field
            outlined
            dense
            style="display:inline-block"
            v-model.number="chromosomalIntervalEnd"
        ></v-text-field>
      </v-col>
    </v-row>
    <div style="display:flex;justify-content:center;margin-bottom:20px">
      <v-btn elevation="0" color="#6aadcf" style="color:white;margin-right:50px" @click="geneSearch(0,pageSize)">Search</v-btn>
      <v-btn elevation="0" color="#6aadcf" style="color:white" @click="exampleSearch">Example</v-btn>
    </div>
    <el-table :data="tableData.slice(0,pageSize)" :border=true>
      <el-table-column
          v-for="(item,index) in tableHeader"
          :prop="item.value"
          :label="item.text"
          :fixed="false"
          :show-overflow-tooltip="true"
          min-width="130"
      >
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="viewInformation(scope.row)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div v-if="pageShow" class="block" style="margin-top:15px;">
      <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="[1,5,10,20]"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
import {statistics,getWorkflow} from '../JS/utils'
export default {
  name: "Gene",
  data: ()=>({
    physicalFitnessSelection:[],//体能下拉框选择数据
    physicalFitnessOption:[],//体能筛选下拉项数据
    geneName:'',//基因名称
    RNAName:'',//RNA名称
    chromosomalIntervalStart:'',
    chromosomalIntervalEnd:'',
    geneTypeSelection:[],//基因类型下拉框选择数据
    geneTypeOption:[
      {
        text:'lncRNA'
      },
      {
        text:'miRNA'
      },
      {
        text:'snRNA'
      },
      {
        text:'protein_coding'
      },
      {
        text:'circRNA'
      }
    ],
    tableData: [],
    tableHeader:[
      {
        text:'基因名',
        value:'geneName'
      },{
        text:'基因id',
        value:'geneId'
      },{
        text:'基因类别',
        value:'geneType'
      },{
        text:'染色体',
        value:'RNA'
      },{
        text:'起位点',
        value:'start'
      },{
        text:'终止位点',
        value:'end'
      },{
        text:'转录体能差异情况',
        value:'transcriptomeDifferences'
      },{
        text:'甲基化体能差异情况',
        value:'differencesMethylationFitness'
      }
    ],
    currentPage:1,
    pageSize:10,
    total: 0, // 总条数
    tableKey: 0,
    pageShow: false,
    tableSelect: [],//表格多选框选中的数据
  }),
  computed: {
    watchObj () {
      return {
        currentPage:this.currentPage,
        pageSize:this.pageSize
      }
    }
  },
  watch: {
    watchObj:{
      handler(newVal, oldVal){
        let oldSize = oldVal.pageSize,
            newSize = newVal.pageSize,
            oldPage = oldVal.currentPage,
            newPage = newVal.currentPage
        if(1){
          let skip = newSize * (newPage-1)
          this.geneSearch(skip,newSize)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.physicalFitnessOption = JSON.parse(sessionStorage.getItem('tenPhysical'))
    this.geneSearch(0,this.pageSize,true)
  },
  methods: {
    ...mapActions("snackbar", ["openSuccessSnackbar","openFailureSnackbar","openInfoSnackbar", "openSnackbar"]),
    ...mapActions("overlay", ["openOverlay","closeOverlay"]),
    async geneSearch(skip,limit,check=true){
      this.pageShow = check
      this.openOverlay()
      let physicalFitnessSelection = this.physicalFitnessSelection,
          geneName = this.geneName,
          chromosomalIntervalStart = this.chromosomalIntervalStart,
          chromosomalIntervalEnd = this.chromosomalIntervalEnd,
          geneTypeSelection = this.geneTypeSelection,
          RNAName = this.RNAName
      //判断是否有选项
      let judge = true
      if(chromosomalIntervalStart!==''){
        if(!!isNaN(chromosomalIntervalStart)){
          judge = false
        }
      }
      if(chromosomalIntervalEnd!==''){
        if(!!isNaN(chromosomalIntervalEnd)){
          judge = false
        }
      }
      if(!judge){
        this.openFailureSnackbar('染色体区间值应该输入数字类型！')
        this.closeOverlay()
        return
      }

      if(judge || !check){
        let filter = {
          "filterOption": {
            "filters": {},
            "group": '61de80fa3f6359003ec98d55',
            "limit": limit,
            "skip": skip
          }
        },
        filters = {
          "workflow": '62f1f4a07f7126003ece1f48'
        }
        geneName ? filters['submission.geneName'] = {"$regex": geneName,"$options":"$i"} : ''
        RNAName ? filters['submission.RNA'] = {"$regex": RNAName,"$options":"$i"} : ''
        geneTypeSelection.length ? filters['submission.geneType'] = {"$eq": geneTypeSelection} : ''
        chromosomalIntervalStart !== '' ? filters['submission.start'] = {"$gte": Number(chromosomalIntervalStart)}:''
        chromosomalIntervalEnd !== '' ? filters['submission.end'] = {"$lte": Number(chromosomalIntervalEnd)} : ''
        if(physicalFitnessSelection.length){
          if(sessionStorage.getItem('project') === '1'){
            filters['submission.transcriptomeArr'] = {"$all": physicalFitnessSelection}
          }else{
            filters['submission.methylationArr'] = {"$all": physicalFitnessSelection}
          }
        }
        filter.filterOption.filters = filters
        let res = await getWorkflow(filter),
            results = res.results
        if(!results.length){
          this.openFailureSnackbar('未查询到数据!')
        }
        this.tableData = []
        for (let i = 0; i < results.length; i++) {
          this.tableData.push(results[i]['submission'])
        }
        this.total=res.total
        this.tableKey = Math.random().toString(36)
      }else{
        this.openFailureSnackbar('请输入正确的搜索条件！')
      }
      this.closeOverlay()
    },
    /**
     * example搜索
     */
    exampleSearch(){
      if(sessionStorage.getItem('project')==='1'){
        this.geneName = "PARD3"
        this.chromosomalIntervalStart = 34109560
        this.chromosomalIntervalEnd = 34815325
        this.geneTypeSelection = 'protein_coding'
        this.RNAName = 'chr10'
        this.physicalFitnessSelection = [
          "绝对力量",
          "上肢耐力",
          "上肢爆发力",
          "下肢垂直爆发力",
          "下肢水平爆发力"
        ]
      }else{
        this.geneName = "OR2AK2"
        this.chromosomalIntervalStart = 247965233
        this.chromosomalIntervalEnd = 247966386
        this.geneTypeSelection = 'protein_coding'
        this.RNAName = 'chr1'
        this.physicalFitnessSelection = [
          "速度",
          "协调性",
          "综合灵敏"
        ]
      }
      this.geneSearch()
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      // this.currentPage = 1;
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    /**
     * 点击表格每条数据的查看按钮，页面跳转
     * @param val {Object} 跳转页面携带的数据
     */
    viewInformation(val){
      this.$router.push({path:'/geneChildrenPage',query:{value:JSON.stringify(val)}})
    },
    handleSelectionChange(val){
      //拿到当前的选中项
      let tableSelection = this.tableSelect
      //拿到当前页数
      //遍历val
      for (let i = 0; i < val.length; i++) {
        //如果tableSelection中没有有val中的数据，push进去，如果有，删除
      }
      console.log('val',val)
    }
  }
}
</script>

<style scoped>

</style>